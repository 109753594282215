import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util';

export class ProductService extends BaseService {
  static async listProduct() {
    try {
      const response = await this.request({ auth: true }).get(`/product/patient/`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async listProductRX() {
    try {
      const response = await this.request({ auth: true }).get(`/product/patient/rx`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async listProductNonRX() {
    try {
      const response = await this.request({ auth: true }).get(`/product/patient/nonrx`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async listProductPricing(productid) {
    try {
      const response = await this.request({ auth: true }).get(`/product/${productid}/pricing`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async getProduct(productid) {
    try {
      const response = await this.request({ auth: false }).get(`/product/${productid}`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}