<template>
  <b-modal
    :size="size"
    hide-header
    hide-footer
    centered
    content-class="dark-bg"
    no-close-on-backdrop
    no-close-on-esc
    no-stacking
    v-model="modal"
  >
    <b-row class="mb-6">
      <b-col cols="12">
        <div class="icon-logo dark"></div>
      </b-col>
    </b-row>
    <div class="modal-card">
      <h2
        class="mb-3 heading color-inherit"
        style="font-size: 26px; line-height: 32px"
      >
        {{ body.title }}
      </h2>
      <p class="mb-5">
        {{ body.description }}
      </p>

      <b-button
        v-for="answer in body.answerlist"
        variant="info"
        pill
        block
        :key="answer.id"
        @click="submitAnswer(answer.id)"
      >
        {{ answer.name }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    body: Object,
    size: String,
  },
  data() {
    return {
      modal: true,
    };
  },
  methods: {
    toggle() {
      this.modal = !this.modal;
    },
    submitAnswer(answer) {
      this.toggle();
      this.$emit("submitAnswer", this.body.id, answer, this.body);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn + .btn {
  border-color: #cdfcb1;
  background-color: transparent;
  color: #cdfcb1;

  &:active {
    background-color: transparent;
    border-color: #cdfcb1;
    box-shadow: 0 0 0 0.15rem rgba(#cdfcb1, 0.5);
  }

  &:focus {
    box-shadow: 0 0 0 0.15rem rgba(#cdfcb1, 0.5);
  }
}
</style>
