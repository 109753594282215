<template>
  <b-card
    class="bg-primary px-4 py-3 text-secondary"
    v-if="show"
    no-body
    v-show="loaded"
  >
    <span>
      <b-row align-h="between">
        <b-col cols="12" sm="6" class="text-center text-sm-left">
          Active Discount: <br />
          <span class="heading text-secondary">
            {{ couponid }}
          </span>
        </b-col>
        <b-col cols="12" sm="6" class="text-center text-sm-right">
          <span v-if="couponData.percent_off">
            Enjoy {{ couponData.percent_off }}% off
          </span>
          <span v-if="couponData.applies_to">
            {{ discountedProduct.name }} for
          </span>
          <span v-if="couponData.duration == 'once'">
            your first treatment plan!
          </span>
          <br />
          <span class="text-small">Discount applied at checkout</span>
        </b-col>
      </b-row>
    </span>
  </b-card>
</template>

<script>
import { OnboardingService } from "@/services/onboarding.service";
import { ProductService } from "@/services/product.service";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    couponid: {
      type: String,
      default: "",
    },
  },
  watch: {
    async couponid(id) {
      this.loaded = false;
      if (id) {
        const { data } = await OnboardingService.checkCoupon(id);
        this.couponData = data;
        if (this.couponData.applies_to) {
          const getProduct = await ProductService.getProduct(
            this.couponData.applies_to.products[0]
          );
          this.discountedProduct = getProduct.data;
        }
      }

      this.loaded = true;
    },
  },
  data() {
    return {
      loaded: false,
      couponData: {},
      discountedProduct: {},
    };
  },
};
</script>

<style>
.text-small {
  font-size: 0.8rem !important;
}
</style>
